import React from "react";
import VideoChat from "../components/VideoChat";

const Room = () => {
  return (
    <React.Fragment>
      <VideoChat />
    </React.Fragment>
  );
};

export default Room;
