import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { socket } from "../socket";
import Peer from "peerjs";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  const [meetingId, setMeetingId] = React.useState("");
  const [validated, setValidated] = useState(false);

  const handleJoinMeeting = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const peer = new Peer();
      peer.on("open", (id) => {
        const data = socket.emit("join-room", {
          roomId: meetingId,
          userId: id,
          name: name,
        });
        if (data?.connected) {
          navigate("/room", {
            state: {
              roomId: 1,
              userId: id,
              name: name,
              role: "user",
              // peer: peer,
            },
          });
        }
      });
    }

    setValidated(true);
  };

  const [hostName, setHostName] = useState("");
  const [validated1, setValidated1] = useState(false);

  const handleCreateMeeting = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const peer = new Peer();
      peer.on("open", (id) => {
        const data = socket.emit("join-room", {
          roomId: 1,
          userId: id,
          name: hostName,
        });
        if (data?.connected) {
          navigate("/room", {
            state: {
              roomId: 1,
              userId: id,
              name: hostName,
              role: "host",
              // peer: peer,
            },
          });
        }
      });
    }

    setValidated1(true);
  };
  return (
    <React.Fragment>
      <Container fluid className="mt-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleJoinMeeting}
              className="border rounded-3 p-3"
            >
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicMeetingId">
                <Form.Label>Meeting ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter meeting id"
                  value={meetingId}
                  onChange={(e) => setMeetingId(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Join Meeting
              </Button>
            </Form>
          </Col>
        </Row>

        <hr />
        <h5 className="text-center">OR</h5>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Form
              noValidate
              validated={validated1}
              onSubmit={handleCreateMeeting}
              className="border rounded-3 p-3"
            >
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  value={hostName}
                  onChange={(e) => setHostName(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Create Meeting
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Home;
