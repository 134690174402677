import React, { useState, useEffect, useRef, useCallback } from "react";
import io from "socket.io-client";
import Peer from "peerjs";
import { useLocation } from "react-router-dom";
import {
  FaEye,
  FaEyeSlash,
  FaLock,
  FaLockOpen,
  FaMicrophone,
  FaMicrophoneSlash,
  FaVideo,
  FaVideoSlash,
} from "react-icons/fa6";

// Video component for rendering video streams
const Video = React.memo(
  ({
    stream,
    isPeerMuted,
    socketRef,
    remoteUserId,
    // isRemoteVideoDisabled = false,
  }) => {
    const videoRef = React.useRef();
    const [isRemoteVideoDisabled, setIsRemoteVideoDisabled] =
      React.useState(false);
    const [isBlur, setIsBlur] = React.useState(false);
    const socket = socketRef?.current;

    React.useLayoutEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    }, [stream, isRemoteVideoDisabled]);

    React.useEffect(() => {
      const handleToggleVideo = (data) => {
        if (data.userId === remoteUserId) {
          setIsRemoteVideoDisabled(data.status);
        }
      };

      socket?.on("toggle-video", handleToggleVideo);

      return () => {
        socket?.off("toggle-video", handleToggleVideo); // Clean up listener
      };
    }, [remoteUserId, socket]);

    React.useEffect(() => {
      const handleBlurVideo = (data) => {
        if (data.userId === remoteUserId) {
          setIsBlur(data.status);
        }
      };

      socket?.on("blur-video", handleBlurVideo);

      return () => {
        socket?.off("blur-video", handleBlurVideo); // Clean up listener
      };
    }, [remoteUserId, socket]);

    React.useEffect(() => {
      const handleFreezeVideo = (data) => {
        if (data.userId === remoteUserId) {
          console.log("handleFreezeVideo", data);
          if (videoRef.current && data.status) {
            console.log("Freezing video", data.status);
            videoRef.current.pause();
          }
          if (videoRef.current && !data.status) {
            videoRef.current.play();
          }
        }
      };

      socket?.on("feerze-video", handleFreezeVideo);

      return () => {
        socket?.off("feerze-video", handleFreezeVideo); // Clean up listener
      };
    }, [remoteUserId, socket]);

    return (
      !isRemoteVideoDisabled && (
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted={isPeerMuted}
          className={isBlur ? "blur-video" : ""}
        />
      )
    );
  }
);

// Main VideoChat component
const VideoChat = () => {
  const location = useLocation();
  const [myStream, setMyStream] = useState(null);
  const [peers, setPeers] = useState({});
  const [peerAudioStatus, setPeerAudioStatus] = useState({});
  const [peerVideoStatus, setPeerVideoStatus] = useState({});
  const [peerVideoBlurr, setPeerVideoBlurr] = useState({});
  const [peerVideoFreeze, setPeerVideoFreeze] = useState({});
  const [messages, setMessages] = useState([]);
  const [inputMsg, setInputMsg] = useState("");
  const [isMuted, setIsMuted] = useState(true);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [error, setError] = useState(null);

  const socketRef = useRef();
  const myPeerRef = useRef();
  const myVideoRef = useRef();

  const userName = location?.state?.name;
  const ROOM_ID = location?.state?.roomId; // Replace with actual room ID logic
  const ishost = location?.state?.role === "host";

  // Initialize media devices (camera and microphone)
  const initializeMediaDevices = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true, // Get both video and audio
      });
      setMyStream(stream);
      if (myVideoRef.current) {
        myVideoRef.current.srcObject = stream;
      }
      return stream;
    } catch (err) {
      console.error("Error accessing media devices:", err);
      handleMediaErrors(err);
    }
  }, []);

  // Handle media device errors
  const handleMediaErrors = (err) => {
    console.error("Error accessing media devices:", err);
    if (["NotFoundError", "DevicesNotFoundError"].includes(err.name)) {
      setError(
        "Camera or microphone not found. Please check your device connections and permissions."
      );
    } else if (
      ["NotAllowedError", "PermissionDeniedError"].includes(err.name)
    ) {
      setError(
        "Permission to access camera and microphone was denied. Please allow access in your browser settings."
      );
    } else {
      setError(
        "An error occurred while trying to access your camera and microphone. Please check your settings and try again."
      );
    }
  };

  // Connect to a new user
  const connectToNewUser = (userId, stream) => {
    const call = myPeerRef.current.call(userId, stream);
    call.on("stream", (userVideoStream) => addPeer(userId, userVideoStream));
    call.on("close", () => removePeer(userId));
  };

  // Add peer to the list of connected peers
  const addPeer = (userId, stream) => {
    setPeers((prevPeers) => ({ ...prevPeers, [userId]: stream }));
    setPeerAudioStatus((prevStatus) => ({ ...prevStatus, [userId]: true })); // Peer audio is muted by default
  };

  // Remove peer from the list of connected peers
  const removePeer = (userId) => {
    setPeers((prevPeers) => {
      const newPeers = { ...prevPeers };
      delete newPeers[userId];
      return newPeers;
    });
    setPeerAudioStatus((prevStatus) => {
      const newStatus = { ...prevStatus };
      delete newStatus[userId];
      return newStatus;
    });
  };

  // Handle socket and peer events
  useEffect(() => {
    socketRef.current = io("https://test-api.nyaynet.online");
    myPeerRef.current = new Peer();

    initializeMediaDevices().then((stream) => {
      if (stream) {
        myPeerRef.current.on("call", (call) => {
          call.answer(stream); // Answer the call with your stream
          call.on("stream", (userVideoStream) =>
            addPeer(call.peer, userVideoStream)
          );
        });

        socketRef.current.on("user-connected", (userId) => {
          connectToNewUser(userId, stream);
        });
      }
    });

    socketRef.current.on("user-disconnected", removePeer);

    myPeerRef.current.on("open", (id) => {
      socketRef.current.emit("join-room", {
        roomId: ROOM_ID,
        userId: id,
        name: userName,
      });
    });

    socketRef.current.on("receive-message", (msg, user) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: msg, userName: user, time: new Date() },
      ]);
    });

    return () => {
      socketRef.current.disconnect();
      myPeerRef.current.destroy();
      if (myStream) {
        myStream.getTracks().forEach((track) => track.stop());
        /* eslint-disable */
      }
    };
  }, [initializeMediaDevices, ROOM_ID, userName]);

  // Toggle own audio (mute/unmute)
  const handleMute = useCallback(() => {
    if (myStream) {
      const audioTrack = myStream.getAudioTracks()[0];
      if (isMuted) {
        audioTrack.enabled = true;
        setIsMuted(false);
      } else {
        audioTrack.enabled = false;
        setIsMuted(true);
      }
    }
  }, [myStream, isMuted]);

  // Toggle own video (on/off)
  const handleVideoToggle = useCallback(() => {
    if (myStream) {
      const videoTrack = myStream.getVideoTracks()[0];
      videoTrack.enabled = !videoTrack.enabled;
      setIsVideoOff(!videoTrack.enabled);
    }
  }, [myStream]);

  // Toggle peer audio (mute/unmute) locally
  const togglePeerAudio = (userId) => {
    setPeerAudioStatus((prevStatus) => ({
      ...prevStatus,
      [userId]: !prevStatus[userId],
    }));
  };
  const togglePeerStream = (userId) => {
    // if (peers[userId]) {
    //   const stream = peers[userId];
    //   stream.getTracks().forEach((track) => (track.enabled = !track.enabled));
    // }
    // setPeerVideoStatus((prevStatus) => ({
    //   ...prevStatus,
    //   [userId]: !prevStatus[userId],
    // }));

    const isVideoEnabled = peerVideoStatus[userId];
    socketRef.current.emit("host-toggle-video", {
      userId,
      status: !isVideoEnabled,
    });
    setPeerVideoStatus((prevStatus) => ({
      ...prevStatus,
      [userId]: !isVideoEnabled,
    }));
  };
  const toggleBlurrStream = (userId) => {
    const isVideoEnabled = peerVideoBlurr[userId];
    socketRef.current.emit("host-blur-video", {
      userId,
      status: !isVideoEnabled,
    });
    setPeerVideoBlurr((prevStatus) => ({
      ...prevStatus,
      [userId]: !isVideoEnabled,
    }));
  };
  const toggleFreezeStream = (userId) => {
    const isVideoEnabled = peerVideoFreeze[userId];
    socketRef.current.emit("host-feerze-video", {
      userId,
      status: !isVideoEnabled,
    });
    setPeerVideoFreeze((prevStatus) => ({
      ...prevStatus,
      [userId]: !isVideoEnabled,
    }));
  };

  // Send chat message
  const handleMessageSubmit = (e) => {
    e.preventDefault();
    if (inputMsg.trim()) {
      socketRef.current.emit("send-message", inputMsg, userName);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: inputMsg, userName, time: new Date() },
      ]);
      setInputMsg("");
    }
  };

  // Display error if any
  if (error) {
    return (
      <div className="error-container">
        <h2>Error</h2>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Try Again</button>
      </div>
    );
  }

  // Format time for messages
  const formatTime = (date) =>
    date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

  return (
    <div className="video-chat-container">
      <div className="video-grid">
        {/* Self Video */}
        <div className="user-video">
          <Video stream={myStream} isPeerMuted={isMuted} />
          <div className="user-name">{userName} (You)</div>
        </div>

        {/* Peer Videos */}
        {Object.entries(peers).map(([userId, stream]) => (
          <div key={userId} className="user-video">
            <Video
              stream={stream}
              isPeerMuted={peerAudioStatus[userId]}
              socketRef={socketRef}
              remoteUserId={userId}
            />
            <div className="user-name">User {userId}</div>
            {ishost && (
              <>
                <button
                  onClick={() => togglePeerAudio(userId)}
                  className="mute-btn"
                >
                  {peerAudioStatus[userId] ? (
                    <FaMicrophoneSlash />
                  ) : (
                    <FaMicrophone />
                  )}
                </button>

                <button
                  onClick={() => togglePeerStream(userId)}
                  className="mute-video-btn"
                >
                  {peerVideoStatus[userId] ? <FaVideoSlash /> : <FaVideo />}
                </button>

                <button
                  onClick={() => toggleBlurrStream(userId)}
                  className="blur-video-btn"
                >
                  {peerVideoBlurr[userId] ? <FaEyeSlash /> : <FaEye />}
                </button>
                <button
                  onClick={() => toggleFreezeStream(userId)}
                  className="freeze-video-btn"
                >
                  {peerVideoFreeze[userId] ? <FaLockOpen /> : <FaLock />}
                </button>
              </>
            )}
          </div>
        ))}
      </div>

      {/* Controls for own audio and video */}
      <div className="controls">
        <button onClick={handleMute}>{isMuted ? "Unmute" : "Mute"}</button>
        <button onClick={handleVideoToggle}>
          {isVideoOff ? "Turn Video On" : "Turn Video Off"}
        </button>
      </div>

      {/* Chatbox */}
      <div className="chat-box">
        <div className="messages">
          {messages.map((msg, index) => (
            <div key={index} className="message">
              <strong>{msg.userName}</strong> {formatTime(msg.time)}
              <br />
              {msg.text}
            </div>
          ))}
        </div>
        <form onSubmit={handleMessageSubmit}>
          <input
            type="text"
            value={inputMsg}
            onChange={(e) => setInputMsg(e.target.value)}
            placeholder="Type a message..."
          />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default VideoChat;
